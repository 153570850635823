import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { KeyUp } from 'src/app/shared/helper/keyup';
import { Employee, Model } from 'src/app/shared/models';
import { EmployeesService, FeedbackService, UserService } from 'src/app/shared/services';

interface HolidayBalance {
  description: string;
  value: number;
  name: string;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public employee: Employee;
  public editMode = false;
  public dirty = false;

  holidayBalances: HolidayBalance[] = [];

  @ViewChild('focus') nameField: ElementRef;

  constructor(
    private employeesService: EmployeesService,
    private userService: UserService,
    private feedback: FeedbackService,
    private router: Router,
  ) {}

  get isAdministrator(): boolean {
    return this.userService.isAdministrator();
  }

  get employeeContractDate(): string {
    return Model.formatDate(this.employee.contractDate);
  }

  set employeeContractDate(value: string) {
    this.employee.contractDate = value && moment(value);
  }

  get employeeEndDate(): string {
    return Model.formatDate(this.employee.endDate);
  }

  set employeeEndDate(value: string) {
    this.employee.endDate = value && moment(value);
  }

  ngOnInit(): void {
    this.employee = this.userService.getLoggedInUser();
    if (this.employee?.id) {
      this.getEmployee();
    }
  }

  keyup(e: KeyboardEvent): void {
    if (KeyUp.charactersAndBackspace(e)) this.dirty = true;
  }

  toggleEdit(): void {
    this.editMode = !this.editMode;
    return this.editMode ? this.enableEdit() : this.disableEdit();
  }

  private enableEdit() {
    this.dirty = false;
    this.editMode = true;
    this.employee = Employee.copy(this.userService.getLoggedInUser());
    this.focusOnFirstField();
  }

  private disableEdit() {
    this.editMode = false;
    this.deselect();
    this.employee = Employee.copy(this.userService.getLoggedInUser());
  }

  private deselect() {
    window.getSelection().removeAllRanges();
  }

  private focusOnFirstField() {
    setTimeout(() => {
      this.nameField.nativeElement.select();
    });
  }

  getEmployee(): void {
    this.employeesService.get(this.employee.id).subscribe((res) => {
      this.employee = res;
      this.userService.setLoggedInUser(res);
      this.updateCurrentHolidayHours();
    });
  }

  updateCurrentHolidayHours(): void {
    const yearly = this.employee.holidayHoursCurrentYear;
    const hoursCarriedForward = this.employee.holidayHoursPreviousYear || 0;
    const hoursApproved = this.employee.approvedHolidayHours || 0;
    const hoursSubmitted = this.employee.submittedHolidayHours || 0;

    const monthRate = (new Date().getMonth() + 1) / 12;
    const currentYearProRata = Math.floor(this.employee.holidayHoursCurrentYear * monthRate);
    const balanceProRata = hoursCarriedForward + currentYearProRata - hoursApproved;

    const holidayYear = this.employee.holidayYear;

    if (this.employee.holidayYear === moment().year()) {
      this.holidayBalances = [
        { name: 'holidayHoursCurrentYear', description: 'Vakantie-uren per jaar', value: yearly },
        { name: 'holidayHoursPreviousYear', description: 'Saldo vakantie-uren vorig jaar', value: hoursCarriedForward },
        {
          name: 'holidayHoursCurrentYearProRata',
          description: 'Opbouw vakantie-uren tot huidige maand',
          value: currentYearProRata,
        },
        {
          name: 'approvedHolidayHours',
          description: 'Opgenomen vakantie-uren tot huidige maand',
          value: hoursApproved,
        },
        {
          name: 'holidayBalanceRemainingProRata',
          description: 'Saldo vakantie-uren huidig jaar tot huidige maand',
          value: balanceProRata,
        },
        {
          name: 'submittedHolidayHours',
          description: 'Ingediende vakantie-uren, niet verwerkt',
          value: hoursSubmitted,
        },
      ];
    } else {
      this.holidayBalances = [
        { name: 'holidayHoursCurrentYear', description: 'Vakantie-uren per jaar', value: yearly },
        {
          name: 'holidayHoursPreviousYear',
          description: 'Saldo vakantie-uren ' + (this.employee.holidayYear - 1),
          value: hoursCarriedForward,
        },
        {
          name: 'holidayHoursCurrentYearProRata',
          description: 'Totaal vakantie-uren eind ' + holidayYear,
          value: yearly + hoursCarriedForward,
        },
        {
          name: 'approvedHolidayHours',
          description: 'Opgenomen vakantie-uren tot huidige maand ' + holidayYear,
          value: hoursApproved,
        },
        {
          name: 'holidayBalanceRemainingProRata',
          description: 'Saldo vakantie-uren eind ' + holidayYear,
          value: yearly + hoursCarriedForward - hoursApproved,
        },
      ];
    }
  }

  /** update profile info in the database */
  submit(): void {
    this.employeesService.update(this.employee.id, this.employee).subscribe({
      next: (res) => {
        this.disableEdit();
        this.feedback.openSuccessToast('De wijzigingen zijn opgeslagen');
        this.employee = res;
        this.userService.setLoggedInUser(res);
      },
      error: (err: unknown) => {
        this.feedback.openErrorToast(err);
      },
    });
  }

  cancel(): void {
    this.disableEdit();
  }

  changePassword(): void {
    this.router.navigate(['medewerker', 'newPassword']);
  }
}
