import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import 'src/environments/environment';
import { Model } from 'src/app/shared/models';

import { HttpService } from './http';

@Injectable({
  providedIn: 'root',
})
export abstract class ResourceService<T extends Model, S, TM extends Model, SM> {
  protected readonly dateFormat = 'YYYY-MM-DD';
  protected backend = ura_environment.servicesUrl;
  private base = ura_environment.servicesUrl + '/services/';
  protected lastFetch: Moment;

  constructor(private networkingService: HttpService) {}

  protected mapSingle = map<S, T>(this.dtoToModel);
  protected mapList = map<SM[], TM[]>((list) => list.map(this.listDtoToModel));

  protected abstract get name(): string;

  protected get endpoint(): string {
    return this.base + this.name + '/';
  }

  public getOrFetchAll(): Observable<TM[]> {
    return this.fetchAll();
  }

  private fetchAll(): Observable<TM[]> {
    return this.networkingService
      .get<SM[]>(this.endpoint)
      .pipe(
        map((list) => {
          this.lastFetch = moment();
          return list.map((e) => this.listDtoToModel(e));
        }),
      )
      .pipe(share());
  }

  public abstract create(t: T): Observable<T>;
  public abstract update(id: number, t: T): Observable<T>;

  createOrUpdate(model: T): Observable<T> {
    console.log('model', model);
    console.log('model.id', model.id);
    return model.id ? this.update(model.id, model) : this.create(model);
  }

  protected abstract modelToDto(a: T): S;
  protected abstract dtoToModel(a: S): T;
  protected abstract listDtoToModel(a: SM): TM;
}
